import React from "react";
import "./styles/home.css"
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormHelperText from "@mui/material/FormHelperText";
import { useNavigate } from "react-router-dom";

type OptionsByBrandType = {
  [key: string]: string[];
}

export default function Home() {
  const [brand, setBrand] = React.useState('');
  const [selectedOption, setSelectedOption] = React.useState('');
  const navigate = useNavigate();

  const handleChangeBrand = (event: SelectChangeEvent) => {
    const selectedBrand = event.target.value;
    setBrand(selectedBrand);
    setSelectedOption('');
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    setSelectedOption(event.target.value);
  };

  const optionsByBrand: OptionsByBrandType = {
    'BLOG FLAMES (coming soon)': ['WOMEN', 'MEN'],
    'SERVICES by HDON': ['PERSONAL', 'BUSINESS'],
    'SHOP FBP (coming soon)': ['WOMEN', 'MEN']
  };

  const secondInputOptions = optionsByBrand[brand] || [];

  const handleOptionClick = (option: string) => {
    const brandName= brand.toLowerCase().replace(/\s+/g, '-'); 
    navigate(`/${brandName}/${option.toLowerCase()}`);
  };

  return (
    <div className="home-page">
      <div className="body">
        <div className="input">
          <FormControl fullWidth>
            <InputLabel id="brand-list-label">Choose a brand</InputLabel>
            <Select
              labelId="brand-list-label"
              id="brand-list"
              value={brand}
              label="Choose a brand"
              onChange={handleChangeBrand}
            >
              <MenuItem value="BLOG FLAMES (coming soon)">BLOG FLAMES (coming soon)</MenuItem>
              <MenuItem value="SERVICES by HDON">SERVICES by HDON</MenuItem>
              <MenuItem value="SHOP FBP (coming soon)">SHOP FBP (coming soon)</MenuItem>
            </Select>
            <FormHelperText> Choose a brand to pre-populate options</FormHelperText>
          </FormControl>
        </div>

        <div className="input">
          <FormControl fullWidth>
            <InputLabel id="second-input-label">Choose an option</InputLabel>
            <Select
              labelId="second-input-label"
              id="second-input"
              value={selectedOption}
              onChange={handleSelectChange}
            >
              {secondInputOptions.map((option, index) => (
                <MenuItem key={index} onClick={() => handleOptionClick(option)} value={option}>{option}</MenuItem>
              ))}
            </Select>
            <FormHelperText> Pre-populated based on brand</FormHelperText>
          </FormControl>
        </div>
      </div>
    </div>
  );
}




