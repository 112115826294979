import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./styles/navigation.css";

function Navigation() : JSX.Element {
  const [isOpen] = useState(false);

  const location = useLocation();
  const isActive = (path: string) => {
    return location.pathname === path;
  };

    const getBackgroundColor = (pathname: string) => {
      if (pathname.includes('/business')) {
        return '#7D3201';
      }
      switch (pathname) {
        case '/services-by-hdon/personal/her':
          return '#DA70D6';
        case '/services-by-hdon/personal/him':
            return '#0f95e9';
        // case '/services-by-hdon/business/*':
        //       return '#7D3201';
        default:
          return '#636363';
      }
    };

    const backgroundColor = getBackgroundColor(location.pathname);

  return (
    <nav className="navigation" style={{ backgroundColor }}>
      <div className="logo">
        <h1 className="main-title">HDŌN</h1>
        <p className="sub-title">The Fashion and Lifestyle Brand</p>
      </div>
      <ul className={isOpen ? "menu open" : "menu"}>
        <li>
          <Link to="/" className={isActive("/") ? "active" : ""}>
            Home
          </Link>
        </li>
        <li>
          <Link to="/about" className={isActive("/about") ? "active" : ""}>
            About
          </Link>
        </li>
        <li>
          <Link to="/contact" className={isActive("/contact") ? "active" : ""}>
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default Navigation;
