import React, { useEffect, useState } from "react";
import "./styles/business_media.css"
import beauty_blogs from "../static_data/beauty_blogs.arrays copy";
import fashion_blogs from "../static_data/fashion_blogs.arrays";
import lifestyle_blogs from "../static_data/lifestyle_blogs.arrays copy 2";
import GridItemContent from "./grid_item";
import NewsletterSubscribe from "./newsletter_subcribe";
import BussinessItem from "./business_item";
import { costume_and_wardrobe, Magazines_and_Editorial_Styling } from "../static_data/costume_and_wardrobe";


export default function BusinessMedia() {

  const [content, setContent] = useState<JSX.Element | string[]>([]);
  const [activeButtonIndex, setActiveButtonIndex] = useState(0);

  const handleLinkClick = (newContent: JSX.Element | string[], buttonIndex: number) => {
    setContent(newContent);
    setActiveButtonIndex(buttonIndex);
  };

  useEffect(() => {
    handleLinkClick(<BussinessItem items={costume_and_wardrobe}/>, 0);
  }, []);

  return (
    <div className="business-media">
      <div className="business-media-body">
        <div className="business-media-inner">
        <h3>Description</h3>
        <p>Our fashion and styling services for fashion business owner or brand owners (FBO) have been created to assist you in having a stress free business. Whatever you need assistance with, we provide and make your operations stress free and seamless. Whether you’ve been in the industry for some years now or you’re  just starting up, we have different services that could assist and gusrantee the success of your business or brand. </p>
        <p>The different areas we provide our fashion and styling services include but is not limited to the following:</p>

        <ul>
          <li>The Fashion Design Process</li>
          <li>Fashion Marketing and Brand Management</li>
          <li>Creation of Promotion Materials & PR</li>
          <li>isual Merchandising & Store Solutions</li>
          <li>Online Retail & Advertising Opportunities </li>
          <li>Custom Styling for Your Clients</li>
        </ul>
        </div>
    </div>


    <div className="blog-links business-class">
      <ul>
          <li>
          <button
              className={`link-button ${activeButtonIndex === 0 ? "active" : ""}`}
              onClick={() => handleLinkClick(<BussinessItem items={costume_and_wardrobe}/>, 0)}
            >
              COSTUME & WARDROBE STYLING
            </button>
          </li>
          <li>
          <button
               className={`link-button ${activeButtonIndex === 1 ? "active" : ""}`}
               onClick={() => handleLinkClick(<BussinessItem items={Magazines_and_Editorial_Styling}/>, 1)}
            >
              MAGAZINES & EDITORIAL STYLING
            </button>
          </li>
          <li>
          <button
               className={`link-button ${activeButtonIndex === 2 ? "active" : ""}`}
               onClick={() => handleLinkClick(<GridItemContent items={lifestyle_blogs}/>, 2)}
            >
              COMMERCIALS & ADVERTISMENT
            </button>
          </li>
          <li>
          <button
              className={`link-button ${activeButtonIndex === 3 ? "active" : ""}`}
              onClick={() => handleLinkClick(<GridItemContent items={fashion_blogs}/>, 3)}
            >
               EVENTS & SHOWSTYLING
            </button>
          </li>
          <li>
          <button
               className={`link-button ${activeButtonIndex === 4 ? "active" : ""}`}
               onClick={() => handleLinkClick(<GridItemContent items={lifestyle_blogs}/>, 4)}
            >
              PHOTOSHOOT STYLING
            </button>
          </li>
          <li>
          <button
               className={`link-button ${activeButtonIndex === 4 ? "active" : ""}`}
               onClick={() => handleLinkClick(<GridItemContent items={lifestyle_blogs}/>, 4)}
            >
              PROMOTIONS & ADs VACANCIES
            </button>
          </li>
        </ul>
      </div>
      <div className="blog-content">
      {typeof content === "string" ? (
    <p>{content}</p>
  ) : (
    content
  )}
      </div>
      <NewsletterSubscribe />
    </div>
  );
}