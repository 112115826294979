import React, { useState } from "react";
import PropTypes from "prop-types";
import "./styles/grid_item.css";

interface GridItem {
  title: string;
  description: string;
  imageUrl: string;
  date: string;
}

interface GridItemContentProps {
  items: GridItem[];
}

export default function GridItemContent({ items }: GridItemContentProps): JSX.Element {
  const itemsPerPage = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(items.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, items.length);

  const visibleItems = items.slice(startIndex, endIndex);

  return (
    <div>
      <div className="grid-content">
        {visibleItems.map((item, index) => (
          <div key={index} className="grid-item">
            <img src={item.imageUrl} alt={item.title} className="item-image" />
            <div className="grid_inner_container">
              <h2>{item.title}</h2>
              <p>{item.description}</p>
              <h5>{item.date}</h5>
            </div>
          </div>
        ))}
      </div>
      <div className="page-counter">
        Page {currentPage} of {totalPages}
      </div>
      <div className="pagination-buttons">
        <button
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          Previous
        </button>
        <button
          disabled={currentPage === totalPages}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          Next
        </button>
      </div>
    </div>
  );
}

GridItemContent.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      imageUrl: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
    })
  ).isRequired,
};
