import React, { useEffect, useState } from "react";
import "./styles/service-type.css";
import GridItemContent from "./grid_item";
import fashion_blogs from "../static_data/fashion_blogs.arrays"
import lifestyle_blogs from "../static_data/lifestyle_blogs.arrays copy 2";
import beauty_blogs from "../static_data/beauty_blogs.arrays copy";
import personal_analysis from "../static_data/personal_analysis.arrays"
import NewsletterSubscribe from "./newsletter_subcribe";
import ImageOverlayItem from "./image_overlay_item";
import { useLocation } from "react-router-dom";

export default function ServiceType() : JSX.Element{
  const [content, setContent] = useState<JSX.Element | string[]>([]);
  const [activeButtonIndex, setActiveButtonIndex] = useState(0);
  const location = useLocation();
  const isForHimRoute = location.pathname === "/services-by-hdon/personal/him";

  const handleLinkClick = (newContent: JSX.Element | string[], buttonIndex: number) => {
    setContent(newContent);
    setActiveButtonIndex(buttonIndex);
  };

  useEffect(() => {
    handleLinkClick(<ImageOverlayItem items={personal_analysis}/>, 0);
  }, []);

  
  return (
    <div data-testid="blog-page">
      <div className="blog-container">
        <div className="blog-left-container">
            <h1>
                {isForHimRoute ? "For Him: Personal Styling" : "For Her: Personal Styling"}
            </h1>
            <p>
              Personal styling which is the basis of all other areas of styling involves:
            </p>

            <ul>
              <li>Providing advice and assistance to individuals on how to improve their image.</li>
              <li>Selecting clothing and accessories (either for daily wear or occasions) that are suitable for clients lifestyle and complement clients features, figure and skin colouring.</li>
              <li>Providing advice on beauty treatment, skin care, hair style, self esteem and etiquett.</li>
              <li>Providing a shopping guide for clients.</li>
            </ul>

            <p>Some of the personal styling services we offer to women include:</p>
            <ol>
              <li>PERSONAL ANALYSIS (Face analysis, Body analysis, Colour analysis, and Image consultation packages)</li>
              <li>OCCASION STYLING (for important events, milestones and weddings)</li>
              <li>PHOTOSHOOT STYLING</li>
              <li>WARDROBE SOLUTIONS & ASSISTANCE</li>
              <li>OUTFIT SELECTION</li>
            </ol>
        </div>
        <div className="blog-right-container">
            <div className={`blog-image-container ${isForHimRoute ? "him-route" : ""}`}></div>
        </div>

      </div>
      <div className="blog-links">
      <ul>
          <li>
          <button
              className={`link-button ${activeButtonIndex === 0 ? "active" : ""} ${isForHimRoute ? "him-route" : ""}`}
              onClick={() => handleLinkClick(<ImageOverlayItem items={personal_analysis}/>, 0)}
            >
              PERSONAL ANALYSIS
            </button>
          </li>
          <li>
          <button
               className={`link-button ${activeButtonIndex === 1 ? "active" : ""} ${isForHimRoute ? "him-route" : ""}`}
               onClick={() => handleLinkClick(<GridItemContent items={fashion_blogs}/>, 1)}
            >
              OCCASION STYLING
            </button>
          </li>
          <li>
          <button
               className={`link-button ${activeButtonIndex === 2 ? "active" : ""} ${isForHimRoute ? "him-route" : ""}`}
               onClick={() => handleLinkClick(<GridItemContent items={lifestyle_blogs}/>, 2)}
            >
              PHOTOSHOOT  STYLING
            </button>
          </li>
          <li>
          <button
              className={`link-button ${activeButtonIndex === 3 ? "active" : ""} ${isForHimRoute ? "him-route" : ""}`}
              onClick={() => handleLinkClick(<GridItemContent items={beauty_blogs}/>, 3)}
            >
               WARDROBE ASSISTANCE
            </button>
          </li>
          <li>
          <button
               className={`link-button ${activeButtonIndex === 4 ? "active" : ""} ${isForHimRoute ? "him-route" : ""}`}
               onClick={() => handleLinkClick(<GridItemContent items={lifestyle_blogs}/>, 4)}
            >
              OUTFIT SELECTION
            </button>
          </li>
        </ul>
      </div>
      <div className="blog-content">
      {typeof content === "string" ? (
    <p>{content}</p>
  ) : (
    content
  )}
      </div>
      <NewsletterSubscribe />
    </div>
  );
}
