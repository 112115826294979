import React from "react";
import "./styles/image_overlay_item.css";
import { Button } from "@mui/material";
import { useLocation } from "react-router-dom";

 
interface ImageOverlayItemProps {
  title: string;
  description: string;
  imageUrl: string;
  buttonText: string;
}

interface ImageOverlayContentProps {
  items: ImageOverlayItemProps[];
}

export default function ImageOverlayItem({ items }: ImageOverlayContentProps): JSX.Element {
  const location = useLocation();
  const isForHimRoute = location.pathname === "/services-by-hdon/personal/him";

  return (
<div className="container">
{items.map((item, index) => (
  <div className="image-item" style={{ backgroundImage: `url(${item.imageUrl})` }}>
      <div className="item-content">
        <h2>{item.title}</h2>
        <p>{item.description}</p>
        <Button variant="contained" color={isForHimRoute ? "primary" : "secondary"}>{item.buttonText}</Button>
      </div>

  </div>
))}
</div>
  );
}
