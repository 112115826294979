import React from "react";
import "./styles/newsletter_subcribe.css";

export default function NewsletterSubscribe() {
  return (
    <div className="newsletter_subscribe">
      <p>Subscribe to our Newsletter</p>
      <form action="your-newsletter-action-url" method="post">
        <input type="email" name="email" placeholder="Enter your email here" required />
        <input type="submit" value="Subscribe" />
      </form>
    </div>
  );
}
