const beauty_blogs = [
  {
    title: "Sample Title 1",
    description: "This is the description for sample item 1.",
    imageUrl: "image-url-1.jpg",
    date: "2023-08-12",
  },
  {
    title: "Sample Title 2",
    description: "This is the description for sample item 2.",
    imageUrl: "image-url-2.jpg",
    date: "2023-08-13",
  },
  {
    title: "Sample Title 3",
    description: "This is the description for sample item 3.",
    imageUrl: "image-url-3.jpg",
    date: "2023-08-14",
  },
  {
    title: "Sample Title 4",
    description: "This is the description for sample item 4.",
    imageUrl: "home.jpg",
    date: "2023-08-15",
  },
  {
    title: "Sample Title 5",
    description: "This is the description for sample item 5.",
    imageUrl: "image-url-5.jpg",
    date: "2023-08-16",
  },
  {
    title: "Sample Title 6",
    description: "This is the description for sample item 6.",
    imageUrl: "image-url-6.jpg",
    date: "2023-08-17",
  },
];

export default beauty_blogs;
