
import React from 'react';
import './App.css';
import Navigation from "./components/navigation";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/home";
import Footer from "./components/footer";
import Contact from './components/contact';
import Blogs from './components/service_type';
import About from './components/about';
import PersonalHome from './components/personal_home';
import ServiceType from './components/service_type';
import BusinessHome from './components/business_home';
import BusinessMedia from './components/business_media';

function App() {
  return (
    <Router>
      <div className="app">
        <Navigation/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/blogs" element={<Blogs />} /> 
          <Route path="/contact" element={<Contact />} />
          <Route path="/services-by-hdon/personal" element={<PersonalHome />} />
          <Route path="/services-by-hdon/business" element={<BusinessHome />} />
          <Route path="/services-by-hdon/personal/her" element={<ServiceType />} />
          <Route path="/services-by-hdon/personal/him" element={<ServiceType />} />
          <Route path="/services-by-hdon/business/media" element={<BusinessMedia />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
