import React, { useState } from "react";
import "./styles/contact.css";


export default function Contact() : JSX.Element{
  const fullNameInput = document.getElementById('fullName');

  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log(e.target.fallName)
    const formElement = e.target as HTMLFormElement;
    console.log(formElement.email.value)

    // Get the form data and create an object
    const formData = {

      fullName: formElement.fullName.value, 
      email: formElement.email.value,
      query: formElement.query.value,
    };
 // Send the POST request to the server
 fetch('http://localhost:3000/api/send-email', {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(formData)
})
.then(res => {
  // Handle the response from the server
  console.log("done")
})
.catch(error => {
  // Handle any errors
  console.log("not done")
});
};

    
  return (
    <div data-testid="contact-page" className="contact-page">
     <div className="contact-container">
     <div className="contact-left-container">
      <div className="contact_image_container"></div>
     </div>
      <div className="contact-right-container">
          <div className="contact-form-container">
          <h3>Contact Us</h3>
          <p>Get in touch with H-Don</p>
            <form>
              <input
                type="text"
                id="fullName"
                name="fullName"
                placeholder="Enter your full name"
                required
              />

              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
                required
              />

              <textarea
                id="query"
                name="query"
                rows={5}
                placeholder="How can we assist you?"
                required
              ></textarea>

              <button onClick={handleSubmit} type="submit">Send Message</button>
            </form>
          </div>
      </div>
     </div>
    </div>
  );
}