import Image_One from "../images/test3.webp";
import Image_Two from "../images/sub.jpeg";

const costume_and_wardrobe = [
  {
    description:
      "Costume or Wardrobe Styling involves working on movies, films, theatre productions  television and social media productions as well as music videos, concerts or tours to provide assistance in the developing/creating the look as well as selecting the wardrobe items for the actors and cast, television and media personalities (news anchor, host, presenter, celebrity), artist, musician/performer.",
    what_we_assit_with: ["Developing The Costume/Look", "Selecting Wardrobe Items For the Character/Cast/Artist/Performer"],
    areas: [
      "Short Films",
      "Short Movies",
      "Movies",
      "Documentaries",
      "Shows",
      "News",
      "Music Videos",
      "Concert",
      "Tours"
    ],
    imageUrl: Image_One,
  },
];


const Magazines_and_Editorial_Styling= [
  {
    description:
      "Magazine or Editorial Styling involves creating concepts and organising photoshoots for the pages in fashion and lifestyle magazines and other media publications",
    what_we_assit_with: [
      "Developing The Costume/Look", 
      "Choosing The Clothing/Apparel For The Editorial Shoot",
      "Choosing The Model For The Editorial Shoot",
      "Styling Assistance For The Shoot",
      "Creative Direction For The Shoot",
      "Selecting The Pictures To Appear In Magazine",
      "Selecting The Items To Appear In The Fashion Product Pages",
      "Writing Articles On Personal Style and Fashion Trends"
    ],
    areas: [
      "Content Writing",
      "Photoshoot Styling",
      "Creative Direction",
      "Editorial Consultation"
    ],
    imageUrl: Image_Two,
  },
];

export { costume_and_wardrobe, Magazines_and_Editorial_Styling };