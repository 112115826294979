import React from "react";
import NewsletterSubscribe from "./newsletter_subcribe";
import "./styles/about.css";
import ImageOne from "../images/image_one.png"
import ImageEight from "../images/image_eight.png"


export default function About() : JSX.Element{
  
  return (
    <div data-testid="about-page" className="about-page">
      </div>
  );
}
