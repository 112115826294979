import Image_Two from "../images/image_two.png";
import Image_Four from "../images/home.png";
import Image_Ten from "../images/image_ten.png";
import Image_Eleven from "../images/image_eleven.png";
import Image_Seven from "../images/image_seven.png";
import Image_Eight from "../images/image_eight.png";

const fashion_blogs = [
  {
    title: "Summer Vibes: The Hottest Trends of 2023",
    description:
      "Discover the latest summer fashion trends that will keep you stylish and comfortable in the heat.",
    imageUrl: Image_Two,
    date: "August 10, 2023",
  },
  {
    title: "Elegant Evening Wear: Red Carpet Inspired",
    description:
      "Get ready to turn heads with our selection of glamorous evening dresses and accessories.",
    imageUrl: Image_Four,
    date: "August 5, 2023",
  },
  {
    title: "Sustainable Fashion: Eco-Friendly Choices",
    description:
      "Explore eco-friendly fashion options that not only look good but also contribute to a better environment.",
    imageUrl: Image_Ten,
    date: "July 28, 2023",
  },
  {
    title: "Classic Denim: Timeless Style for All Seasons",
    description:
      "Learn how to incorporate denim into your wardrobe for a versatile and timeless look.",
    imageUrl: Image_Eleven,
    date: "July 20, 2023",
  },
  {
    title: "Accessorize Right: Elevate Your Outfit",
    description:
      "Discover the power of accessories and how they can transform your outfits from ordinary to extraordinary.",
    imageUrl: Image_Seven,
    date: "July 15, 2023",
  },
  {
    title: "Athleisure Revolution: Fashion Meets Comfort",
    description:
      "Explore the athleisure trend that's redefining fashion by combining style and comfort effortlessly.",
    imageUrl: Image_Eight,
    date: "July 10, 2023",
  },
  {
    title: "Classic Denim: Timeless Style for All Seasons",
    description:
      "Learn how to incorporate denim into your wardrobe for a versatile and timeless look.",
    imageUrl: Image_Eleven,
    date: "July 20, 2023",
  },
  {
    title: "Accessorize Right: Elevate Your Outfit",
    description:
      "Discover the power of accessories and how they can transform your outfits from ordinary to extraordinary.",
    imageUrl: Image_Seven,
    date: "July 15, 2023",
  },
  {
    title: "Athleisure Revolution: Fashion Meets Comfort",
    description:
      "Explore the athleisure trend that's redefining fashion by combining style and comfort effortlessly.",
    imageUrl: Image_Eight,
    date: "July 10, 2023",
  },
];

export default fashion_blogs;
