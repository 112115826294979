import React from "react";
import "./styles/business_item.css"
 
interface BusinessItemItemProps {
  description: string;
  what_we_assit_with: string[];
  areas: string[];
  imageUrl: string;
}

interface BusinessItemContentProps {
  items: BusinessItemItemProps[];
}

export default function BussinessItem({ items }: BusinessItemContentProps): JSX.Element {

  return (
<div className="business-item-container">
{items.map((item, index) => (
    <div className="business-item-inner-container">
      <div className="left-service-business-item">
      <h2>Description</h2>
         <p>{item.description}</p>

         <h2>What We Assist With </h2>
         <ol>
            {
                item.what_we_assit_with.map((assit_list, index) => (
                    <li>{assit_list}</li>
                ))
            }
         </ol>

         <h2>Examples of Areas </h2>
         <ul>
            {
                item.areas.map((area_list, index) => (
                    <li>{area_list}</li>
                ))
            }
         </ul>
      </div>

         <div className="right-service-business-item">
         <img src={item.imageUrl} alt="lady"  className="business-item-image"/>
         </div>
    </div>
))}
</div>
  );
}
