import Image_Two from "../images/image_two.png";
import Image_Ten from "../images/image_ten.png";
import Image_Eleven from "../images/image_eleven.png";
import Image_Seven from "../images/image_seven.png";

const personal_analysis = [
  {
    title: "FACE ANALYSIS",
    description:
      "Discover the latest summer fashion trends that will keep you stylish and comfortable in the heat.",
    imageUrl: Image_Two,
    buttonText: "Explore",
  },
  {
    title: "BODY ANALYSIS",
    description:
      "Get ready to turn heads with our selection of glamorous evening dresses and accessories.",
    imageUrl: Image_Eleven,
    buttonText: "Explore",
  },
  {
    title: "COLOR ANALYSIS",
    description:
      "Explore eco-friendly fashion options that not only look good but also contribute to a better environment.",
    imageUrl: Image_Seven,
    buttonText: "Explore",
  },
  {
    title: "COLOR ANALYSIS",
    description:
      "Explore eco-friendly fashion options that not only look good but also contribute to a better environment.",
    imageUrl: Image_Ten,
    buttonText: "Explore",
  },
];

export default personal_analysis;
