import React from "react";
import "./styles/personal_home.css"
import Button from '@mui/material/Button'
import { useNavigate } from "react-router-dom";
import Image from "../images/personal_home.jpeg";

export default function PersonalHome() {
  const navigate = useNavigate();



  const handleMenClick = () => {
    navigate('/services-by-hdon/personal/him');
  };

  const handleWomenClick = () => {
    navigate('/services-by-hdon/personal/her');
  };

  return (
    <div className="service-home-page">
      <div className="left-service-home-page">
        <h1>Fashion & Personal Styling Services For Individuals</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed fringilla, nunc id sagittis aliquam, est lorem euismod mi, in consectetur diam ipsum eu magna.</p>

        <div className="buttons">
          <Button className="button" onClick={handleMenClick} variant="contained">FOR MEN</Button>
          <Button className="button" onClick={handleWomenClick} variant="contained" color="secondary">For Women</Button>
          </div>
      </div>

      <div className="right-service-home-page">
        <img src={Image} alt="My Image" />
        </div>
    
    </div>
  );
}
