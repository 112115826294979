import React from "react";
import { FaPhoneAlt, FaSearchLocation } from "react-icons/fa";
import { AiFillFacebook, AiFillInstagram, AiFillTwitterSquare, AiTwotoneMail } from "react-icons/ai";
import { BsFillBasket3Fill } from "react-icons/bs";
import "./styles/footer.css";
import { useLocation } from "react-router-dom";

const today = new Date();
const year = today.getFullYear();

export default function Footer() {
  let location = useLocation();

  const getBackgroundColor = (pathname: string) => {
    if (pathname.includes('/business')) {
      return '#7D3201';
    }
    switch (pathname) {
      case '/services-by-hdon/personal/her':
        return '#DA70D6';
      case '/services-by-hdon/personal/him':
          return '#0f95e9';
      // case '/services-by-hdon/business':
      //     return '#7D3201';
      default:
        return '#636363';
    }
  };

  const backgroundColor = getBackgroundColor(location.pathname);

  if(location.pathname !== "/"){
    return (
      <footer data-testid="footer" style={{ backgroundColor }}>
           <div className="footer-container">
           <div className="footer-left-container">
                <h3> <BsFillBasket3Fill className="footer-icon footer-basket"/> H-Don For You</h3>
                <p> Fashion Delivered To Your Doorstep!</p>
  
                <div className="social-media-links">
                <AiFillFacebook className="icon" />
                <AiFillTwitterSquare className="icon" />
                <AiFillInstagram className="icon" />
              </div>
            </div>
  
              <div className="footer-right-container">
                <p>Need Assistance?</p>
                <h4>Our team will attend to your inquiry at the earliest opportunity.</h4>
                <ul >
                <li>
                    <a href="#">
                      <FaPhoneAlt className="footer-icon" />
                    </a>
                    +234 803 705 13885, +234 812 828 6155{" "}
                  </li>
                  <li>
                    <a href="#">
                      <AiTwotoneMail className="footer-icon" />
                      info@hdon.com{" "}
                    </a>
                  </li>
                </ul>
          </div>
           </div>
        <p className="copyright">
            {" "}
            &copy; Copy Rights {year}. H-Don Fashion. All Rights Reserved
          </p>
      </footer>
    );
  }
}
