import React from "react";
import "./styles/business_home.css"
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";


export default function BusinessHome() {

  const navigate = useNavigate();
  
  const handleMediaClick = () => {
    navigate('/services-by-hdon/business/media');
  };

  return (
    <div className="business-page">
      <div className="body">
        <div className="business_buttons">
          <Button className="business_button" variant="outlined">FASHION BUSSINESSESS & BRANDS (FBB)</Button>
          <Button className="business_button" variant="outlined" onClick={handleMediaClick}>MEDIA ART CREATIVES ENTERTAINMENT</Button>
        </div>
        
      </div>
    </div>
  );
}




